import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="Layout--footer">
    <div className="Layout--footer-border-container">
      <b>limericked</b> is a misguided quest to rewrite all 154 of Shakespeare’s
      sonnets (and eventually, other things) as limericks.{" "}
      <Link to="/about">You can learn more about the project here.</Link>
    </div>
  </footer>
)

export default Footer
